import http from "../../helpers/http-common";

class VehicleDataService {
    getAll() {
        return http.get("/vehicles");
    }

    getTypes() {
        return http.get("/vehicles/types");
    }

    get(id) {
        return http.get(`/vehicles/${id}`);
    }

    create(data) {
        return http.post("/vehicles", data);
    }

    update(id, data) {
        return http.put(`/vehicles/${id}`, data);
    }

    delete(id) {
        return http.delete(`/vehicles/${id}`);
    }

    deleteAll() {
        return http.delete('/vehicles');
    }

    findByName(name) {
        return http.get(`/vehicles?name=${name}`);
    }
    findByLicensePlate(licensePlate) {
        return http.get(`/vehicles?licensePlate=${licensePlate}`);
    }
}

export default new VehicleDataService();
